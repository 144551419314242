<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :parent-id="orderId"
      actions-mode="inline"
      :model="model"
      :columns="columns"
      :read-only="readOnly"
      :editable-columns="editableColumns"
      :cell-classes="cellClasses"
      :custom-actions="customActions"
      @updated="onItemsTableUpdate"
      @inserted="onItemsTableInsert"
      @deleted="onItemsTableDelete"
      @loaded="onItemsTableLoad"
    ></TableWrapper>
  </div>
</template>

<script>
import TableWrapper from '@/components/DataView/TableWrapper'
import models from '@/models'
import { on } from 'https-proxy-agent'

export default {
  name: 'IncomingOrderItemsTable',
  props: {
    orderId: {
      type: [String, Number],
      default: ''
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: { TableWrapper },
  data: function () {
    return {
      data: [],
      model: models.warehouse.incomingOrderItems,
      columns: models.warehouse.incomingOrderItems.entities.map(e => e.name),
      editableColumns: models.warehouse.incomingOrderItems.entities
        .filter(e => e.readonly == false)
        .map(e => e.name),

      cellClasses: {
        'Bottles Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Bottles Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Bottles Diff'] > 0
          }
        ],
        'Cases Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Cases Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Cases Diff'] > 0
          }
        ]
      },
      customActions: [
        /*
        {
          click: this.onItemsLookupClick,
          title: "Warehouse",
          icon: "search"
        }
        */
      ]
    }
  },
  computed: {},
  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    async initialize () {},
    getData () {
      return this.$refs[this.model.name].getData()
    },
    getDataSet () {
      return this.$refs[this.model.name].getDataSet()
    },

    onItemsTableInsert () {
      /*
      console.log("onItemsTableInsert.e", e.uid);
      let data = this.$refs[this.model.name].getDataSet();
      console.log("onItemsTableInsert.data", data);



      let insertedProductRecords = data.filter(
        i =>
          i["Finished Product_ID"] == e["Finished Product_ID"] &&
          i["Bottle Size_ID"] == e["Bottle Size_ID"] &&
          i["Cost per Case"] == e["Cost per Case"] &&
          i["Organic Status_ID"] == e["Organic Status_ID"]
      );

      if (insertedProductRecords.length == 2){
//remove inserted
      data = data.filter(i => i.ID !== e.uid);
//add
insertedProductRecords[0]

      }
*/

      /* 
Bottle Size: "750 ml"
Bottle Size_ID: 1
Bottles: "5"
Bottles Diff: 0
Bottles WH: 0
Cases: (...)
Cases Diff: (...)
Cases WH: (...)
Cost per Case: (...)
Finished Product: "_finished_product_test"
Finished Product_ID: "91"
ID: (...)
Item Status: (...)
Line Cost: (...)
Organic Status: (...)
Organic Status_ID: (...)
isInserting: (...)
isLoading: (...)
uid: (...)
*/

      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    onItemsTableDelete () {
      this.$emit('changed', this.getDataSet())
    },
    onItemsTableUpdate () {
      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    updateComputedColumns () {
      let data = this.$refs[this.model.name].getDataSet()

      for (let row of data) {
        row['Line Cost'] = this.$helpers.round(
          (row['Cost per Case'] * row['Bottles']) / 6,
          4
        )
        row['Cases'] = this.$helpers.round(row['Bottles'] / 6, 2)
      }

      this.$refs[this.model.name].updateDataSet(data)
    },
    onItemsLookupClick () {},
    onItemsTableLoad (e) {
      this.$emit('loaded', e)
      // this.updateComputedColumns()
      let items = this.$refs[this.model.name].getDataSet()

      // console.log('IncomingOrderItemsTable.onItemsTableLoad', e, items)

      this.$emit('loaded', items)
    }
  }
}
</script>

<style scoped>
::v-deep .table-responsive {
  overflow: visible !important;
}
::v-deep table {
  overflow: visible !important;
}
</style>
