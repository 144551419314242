<template>
  <div class="template" id="confirmationLetter">
    <!--  style="width: 210mm; height: 297mm; padding: 5mm; border: 1px solid #000;" -->
    <!-- Company Info Table -->

    <table cellpadding="10" style="border:0.5px solid black">
      <tr>
        <td colspan="2" style="border:0;">
          <div
            id="logoId"
            style=" font-size: 7em; text-align: center; width: 100%; letter-spacing: 10px;margin: 0;line-height:normal;font-family: 'Commissioner', sans-serif; font-weight:700"
          >
            KOVAL
          </div>
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          4241 NORTH RAVENSWOOD AVENUE | CHICAGO, ILLINOIS 60613
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          PHONE +1(312) 878-7988 | FAX +1(312) 275 8595
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          info@koval-distillery.com www.koval-distillery.com
        </td>
      </tr>
    </table>

    <br />

    <!-- Date -->
    <div style="text-align:right;" contenteditable="true">
      {{ data['PO Date'] }}
    </div>

    <br />

    <!-- Client and Warehouse Information Table -->
    <table cellpadding="10" style="border:0;">
      <tr style="border: 0.5px solid black;">
        <td colspan="2" contenteditable="true">
          <center>
            <b> {{ data['Account Name'] }}</b>
            <br />
            PO #: {{ data['PO Number'] }}
          </center>
        </td>
      </tr>

      <!-- Bill to and Ship to Information -->
      <tr class="custom-border">
        <td colspan="2" style="border:0; text-align:center;">
          <table
            cellpadding="10"
            style="border:0px solid black; width:100%; font-size:11px;"
          >
            <tr>
              <td
                style="border:0; text-align:center; width:50%;"
                contenteditable="true"
              >
                <b>Bill To:</b><br />
                {{ data.Distributor['Street'] }}<br />
                {{ data.Distributor['Postal Code'] }}
                {{ data.Distributor['City'] }} <br />
                {{ data.Distributor['Country'] }}
              </td>
              <td
                style="border:0; text-align:center; width:50%;"
                contenteditable="true"
              >
                <b>Ship To:</b><br />
                {{ data.Distributor['Ship Street'] }}<br />{{
                  data.Distributor['Ship Postal Code']
                }}
                {{ data.Distributor['Ship City'] }} <br />
                {{ data.Distributor['Ship Country'] }}
              </td>
            </tr>
            <tr>
              <td style="border:0; text-align:center;" contenteditable="true">
                {{ data.Distributor['Billing Contact'] }}<br />
                {{ data.Distributor['Billing Email'] }}
              </td>
              <td style="border:0; text-align:center;" contenteditable="true">
                {{ data.Distributor['Shipping Contact'] }}<br />
                {{ data.Distributor['Shipping Email'] }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <!-- Order Details -->
    <table cellpadding="10" style="border:0; font-size:11px;">
      <tr>
        <td class="custom-border" contenteditable="true">
          <b>Order Confirmation #:</b> {{ data['ID'] }}
        </td>

        <td colspan="2" class="custom-border" contenteditable="true">
          <b>Country of Origin:</b> USA
        </td>
        <td class="custom-border" contenteditable="true">
          <b>Shipping:</b> EXW
        </td>
      </tr>
      <tr>
        <td class="custom-border" contenteditable="true">
          <b>Estimated Ship Date:</b> {{ data['Est Finish Date'] }}
        </td>
        <td colspan="2" class="custom-border" contenteditable="true">
          <b>Warehouse:</b> {{ data['Warehouse'].label }}
        </td>

        <td class="custom-border" contenteditable="true">
          <b>Hours:</b> {{ data['Warehouse Hours'] }}
        </td>
      </tr>
      <tr>
        <td class="custom-border" contenteditable="true"></td>
        <td class="custom-border" contenteditable="true"></td>
        <td class="custom-border" contenteditable="true"></td>
        <td class="custom-border" contenteditable="true">
          <b>Payment Terms:</b> {{ data.Distributor['Terms'] }}
        </td>
      </tr>
    </table>

    <table
      style="margin-top:10px;page-break-inside: avoid;"
      class="order-table"
    >
      <thead>
        <tr style="font-size: 11px">
          <td>#</td>
          <td>Product Name</td>
          <td>Status</td>
          <td>Class</td>
          <td>Alc By Vol</td>
          <td>Bottle Size</td>
          <td># Bottles</td>
          <td># Cases</td>
          <td>Price per Case</td>
          <td>Line Cost</td>
        </tr>
      </thead>
      <tbody style="border:0; font-size:11px;">
        <tr v-for="(item, i) of items" :key="item.ID || i">
          <td>
            <div
              class="left-btn hide-in-print"
              @click="() => items.splice(i, 1)"
            >
              ➖
            </div>
            {{ i + 1 }}
          </td>

          <td style="padding:0;">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'productName')"
              style="width:10em"
            >
              {{ item.productName }}
            </div>
          </td>

          <td style="padding:0;">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'status')"
              style="width:6em"
            >
              {{ item.status }}
            </div>
          </td>

          <td style="padding:0;">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'class')"
              style="width:6em"
            >
              {{ item.class }}
            </div>
          </td>

          <td style="padding:0;width:7em">
            <span
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'alcByVol')"
              style="display: inline-block;width:3em"
            >
              {{ item.alcByVol }}
            </span>
            <span>%</span>
          </td>

          <td style="padding:0;width:7em">
            <span
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'bottleSize')"
              style="display: inline-block;width:2em"
            >
              {{ item.bottleSize }}
            </span>
            <span>ml</span>
          </td>
          <td style="padding:0;">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'bottles')"
              style="width:6em"
            >
              {{ item.bottles }}
            </div>
          </td>
          <td style="padding:0">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'cases')"
              style="width:6em"
            >
              {{ item.cases }}
            </div>
          </td>

          <td style="padding:0;">
            <div
              contenteditable="true"
              @blur="onInput($event.target.textContent, item, 'pricePerCase')"
              style="width:6em"
            >
              {{ item.pricePerCase }}
            </div>
          </td>
          <td contenteditable="false" style="width:10em">
            {{ data['Currency']?.label }} {{ item.cases * item.pricePerCase }}
          </td>
        </tr>

        <tr>
          <td>
            <div
              v-if="items.length < 10"
              class="left-btn hide-in-print"
              @click="
                () =>
                  items.push({
                    ID: Math.random(),
                    productName: '',
                    status: '',
                    class: '',
                    alcByVol: '0',
                    cases: '0',
                    bottleSize: '0',
                    bottles: '0',
                    pricePerCase: '0',
                    lineCost: '0'
                  })
              "
            >
              ➕
            </div>
          </td>
        </tr>
        <tr v-if="orderDiscount > 0">
          <td colspan="10" style="text-align:right; font-weight:bold">
            Order Amount: {{ data['Currency']?.label }}
            {{ orderTotal.toFixed(2) }}
            <br />
            Discount: {{ data['Currency']?.label }}
            {{ orderDiscount.toFixed(2) }}
            <br />
          </td>
        </tr>
        <tr>
          <td colspan="10" style="text-align:right; font-weight:bold">
            Total Order: {{ data['Currency']?.label }}
            {{ (orderTotal - orderDiscount).toFixed(2) }}
          </td>
        </tr>
        <tr>
          <td colspan="9" style="text-align:right;text-decoration:underline">
            <div>Bottles:</div>
          </td>
        </tr>
        <tr>
          <td colspan="10" style="text-align:right">
            <div v-for="(total, size) in groupedItems" :key="size">
              <div>{{ size }} ml: {{ total }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table
      cellpadding="10"
      style="border:0.5px solid black;page-break-inside: avoid;"
    >
      <tr>
        <td colspan="2" class="custom-border" contenteditable="true">
          <center><b>Bank Info US</b></center>
        </td>
      </tr>
      <tr>
        <td class="custom-border" contenteditable="true">
          Bank Name: Chase Bank
        </td>
        <td class="custom-border" contenteditable="true">
          Account Holder Name: KOVAL Distillery
        </td>
      </tr>
      <tr>
        <td class="custom-border" contenteditable="true">
          Account Number: please contact us
        </td>

        <td class="custom-border" contenteditable="true">
          Routing Number: please contact us
        </td>
      </tr>
      <tr>
        <td colspan="2" class="custom-border" contenteditable="true">
          <center>
            Street Address: 1825 W Lawrence City: Chicago State: IL Zip: 60640
            Country: USA
          </center>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { on } from 'https-proxy-agent'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    order_date: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      is_shipping_identical: true,
      billing_address: '<b>billing_address:</b> 123 Main St, Anytown, USA',
      items: [],
      tax_percent: 0,
      shipping: 0,
      item_test: ''
    }
  },

  mounted () {
    /*
    // initialize contenteditable="true" fields manually
    this.$refs.billing_address.innerHTML = this.billing_address
    this.$refs.tax_percent.innerHTML = this.tax_percent
    this.$refs.shipping.innerHTML = this.shipping + '.00'
    */
    this.cloneData()
  },
  computed: {
    groupedItems () {
      return this.items.reduce((acc, item) => {
        if (!acc[item.bottleSize]) acc[item.bottleSize] = 0

        acc[item.bottleSize] += parseInt(item.bottles, 10)

        return acc
      }, {})
    },
    today () {
      var date = new Date()
      return date
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '.')
    },
    next_month () {
      var date = new Date()
      date.setDate(date.getDate() + 30)
      return date
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '.')
    },

    orderTotal () {
      return this.items.reduce(
        (acc, item) => (acc += item.cases * item.pricePerCase),
        0
      )
    },
    orderDiscount () {
      return parseFloat(this.data['Discount']) || 0
    }
  },
  methods: {
    onInput (value, item, key) {
      console.log('onInput:', value, item, key)
      item[key] = value.trim()
    },
    preventLineBreaks (e) {
      if (e.which == 13) e.preventDefault()
    },
    cloneData () {
      this.items = []
      this.data['items'].forEach(item => {
        this.items.push({
          key: Math.random(),
          productName: item['Finished Product'],
          status: item['Organic Status'],
          class: item['Class'],
          alcByVol: item['Alc By Vol'],
          cases: item['Cases'],
          bottleSize: item['Bottle Size'],
          bottles: item['Bottles'],
          pricePerCase: item['Cost per Case'],
          lineCost: item['Cases'] * item['Cost per Case']
        })
      })
    }
  },
  watch: {
    data: {
      handler (newVal) {
        this.cloneData()

        /*
        this.items = []
        newVal['Items'].forEach(item => {
          item['productName'] = item['Finished Product']
          item['status'] = item['Organic Status']
          item['class'] = item['Class']
          item['alcByVol'] = item['Alc By Vol']
          item['cases'] = item['Cases']
          item['bottleSize'] = item['Bottle Size']
          item['bottles'] = item['Bottles']
          item['pricePerCase'] = item['Cost per Case']
          item['lineCost'] = item['Cases'] * item['Cost per Case']

          this.items.push(item)
        })
        */
      },

      deep: true,
      immediate: true
    },
    items: {
      handler (newVal) {
        //console.log('template.watch.items:', newVal)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Commissioner:wght@100..900&display=swap');

.custom-border {
  border: 0.5px solid black;
}
table {
  width: 100%;
  border-spacing: 0;
}
table td {
  position: relative;
  padding: 6px;
  vertical-align: top;
}
table.order-table td {
  vertical-align: middle;
}
table thead td {
  font-weight: bold;
}
table tbody td {
  border-top: solid 1px #ccc;
}
input[type='number'],
input[type='text'] {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: #ffffed;
  padding: 6px;
}
*[contenteditable='true'] {
  background: #ffffed;
  cursor: text;
  transition: padding 0.05s linear;
}
span[contenteditable='true']:focus {
  padding: 0 4px;
}
@media print {
  table td {
    background: none;
  }
  *[contenteditable='true'] {
    background: none;
  }
  input {
    background: none;
  }
  .hide-in-print {
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'],
  input[type='text'] {
    -moz-appearance: textfield;
    background: none;
  }

  .no-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  table {
    page-break-inside: avoid;
  }
  tr {
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  h1,
  h2,
  h3,
  p,
  div {
    page-break-after: auto;
    page-break-before: auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.order {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.left-btn {
  position: absolute;
  left: -40px;
  top: 2px;
}
.left-btn:hover {
  opacity: 0.6;
}
</style>
