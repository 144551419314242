var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main"
  }, [_c('vue-document-editor', {
    ref: "editor",
    staticClass: "editor",
    attrs: {
      "content": _vm.content,
      "overlay": _vm.overlay,
      "zoom": _vm.zoom,
      "page_format_mm": _vm.page_format_mm,
      "page_margins": _vm.page_margins,
      "display": _vm.display
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }